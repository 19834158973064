/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Plyr, Swiper, gsap) => {
		/*
		|
		| Constants
		|-----------
		*/
		const
			$body = $('body')
			;


		/*
		|
		| Button anim
		|-----------------
		*/
		$(".button_su_inner").mouseenter(function (e) {
			var parentOffset = $(this).offset();
			var relX = e.pageX - parentOffset.left;
			var relY = e.pageY - parentOffset.top;
			$(this).prev(".su_button_circle").css({ "left": relX, "top": relY });
			$(this).prev(".su_button_circle").removeClass("desplode-circle");
			$(this).prev(".su_button_circle").addClass("explode-circle");

		});
		$(".button_su_inner").mouseleave(function (e) {
			var parentOffset = $(this).offset();
			var relX = e.pageX - parentOffset.left;
			var relY = e.pageY - parentOffset.top;
			$(this).prev(".su_button_circle").css({ "left": relX, "top": relY });
			$(this).prev(".su_button_circle").removeClass("explode-circle");
			$(this).prev(".su_button_circle").addClass("desplode-circle");
		});


		/*
		|
		| Swiper gallery news
		|-----------
		*/
		if ($('.slider-realisations').length) {
			let sliderreal = new Swiper(".slider-realisations", {
				slidesPerView: 4.2,
				spaceBetween: 30,
				loop: true,
				speed: 1000,
				breakpoints: {
					0: {
						slidesPerView: 1.2
					},
					992: {
						slidesPerView: 2.2
					},
					1200: {
						slidesPerView: 3.2
					},
					1400: {
						slidesPerView: 4.2
					}
				},
				navigation: {
					prevEl: ".swiper-button-prev",
					nextEl: ".swiper-button-next"
				}, on: {
					slideChange: function () {
						var swipercounter = document.getElementById('swiper-counter');
						swipercounter.innerHTML = (sliderreal.realIndex + 1) + ' <span></span> ' + (slides_count.length - slides_count_dup.length);
					}
				}
			});

			let slides_count = document.getElementsByClassName("swiper-slide");
			let slides_count_dup = document.getElementsByClassName("swiper-slide-duplicate");

			var swipercounter = document.getElementById('swiper-counter');
			swipercounter.innerHTML = (sliderreal.realIndex + 1) + ' <span></span> ' + (slides_count.length - slides_count_dup.length);

		}

		/*
		|
		| Plyr
		|-----------------
		*/
		const $customPlyrVideo = jQuery('.custom-plyr-video');
		$.each($customPlyrVideo, function () {
			const $video = $(this);
			const $videoPoster = $video.find('.item-poster');
			const $videoIcon = $video.find('.item-play-icon');
			const videoId = $video.data('id');
			const type = $video.data('type');
			let player = new Plyr(`#${videoId}`);
			$(`#${videoId}`).addClass('custom-plyr-video');

			player.on("play", (event) => {
				var delayInMilliseconds = 500;
				setTimeout(function () {
					jQuery('.item-poster').css('opacity', '0');
				}, delayInMilliseconds);
			});
		});

		/*
		|
		| Anchor
		|-----------------
		*/
		$('a[href^="#"]').click(function (e) {
			e.preventDefault();
			var target = $($(this).attr('href'));
			if (target.length) {
				var scrollTo = target.offset().top;
				$('body, html').animate({ scrollTop: scrollTo + 'px' }, 800);
			}
		});


		/*
		|
		| Scroll menu
		|-----------------
		*/
		let position = $(window).scrollTop();
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();

			if (position > 50) {
				$('#header').addClass('active');
			} else {
				$('#header').removeClass('active');
				$('#header').removeClass('fixed');
			}
			if (position > 50) {
				if (scroll > position) {
					$('#header').addClass('fixed');
				} else {
					$('#header').removeClass('fixed');
				}
			}
			position = scroll;
		});

		/*
		|
		| Home text change
		|-----------------
		*/
		(function () {
			var changetext = $(".change-text .title-yellow");
			var changetextIndex = -1;
			function showNextText() {
				++changetextIndex;

				let $item = changetext.eq(changetextIndex % changetext.length)

				gsap.to($item, {
					opacity: 1,
					// display: 'inline-block',
					duration: .5
				})

				gsap.fromTo($item.find('.title-yellow-full'), {
					clipPath: 'polygon(0% 0%, 0% 0%, 0% 120%, 0% 120%)'
				}, {
					clipPath: 'polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%)',
					duration: 1.4,
					ease: 'power2.inOut',
					delay: 1
				})

				gsap.to($item, {
					opacity: 0,
					// display: 'none',
					duration: .5,
					delay: 4,
					onComplete: function () {
						showNextText()
					}
				})
			}
			showNextText();
		})();

		/*
		|
		| Home .bigtitle
		|-----------------
		*/
		if ($('#page-home').length > 0) {
			document.querySelectorAll('.bigtitle .bigtitle-full').forEach(function (el) {
				gsap.fromTo(el, {
					clipPath: 'polygon(0% 0%, 0% 0%, 0% 120%, 0% 120%)',
				}, {
					clipPath: 'polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%)',
					duration: 1.4,
					ease: 'power2.inOut',
					scrollTrigger: {
						trigger: el,
						start: 'top-=200 75%'
					}
				})
			})
		}




	}
}
