/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/js/swiper.min';
import gsap from "gsap";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import Plyr from 'plyr/src/js/plyr.js';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import etudesdecas from '@pages/etudes-de-cas.js';
import contact from '@pages/contact.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, gsap, ScrollTrigger, Menu, Kira]
  },
  {
    'file': main,
    'dependencies': [app, Plyr, Swiper, gsap]
  },
  {
    'file': news,
    'dependencies': [app],
    'resolve': '#page-news-archive'
  },
  {
    'file': etudesdecas,
    'dependencies': [app],
    'resolve': '#page-etudes-de-cas'
  },
  {
    'file': contact,
    'dependencies': [app],
    'resolve': '#page-contact'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

