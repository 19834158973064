/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        const container = $('.list-container')
        const ajaxRoute = '/ajax/etudes-de-cas'
        const filterArr = {};

        let xhr;



        $(".filter-all li").on("click", function () {
            $(".filters li").removeClass("active");
            $(this).addClass("active");

            // console.log(filterArr);
            sendAjaxRequest('filter', {});
        });

        // Filtering jobs
        $(".filter-job li").on("click", function () {
            $(".filter-all li, .filter-job li").removeClass("active");
            $(this).addClass("active");

            let category = $(this).data("slug")
            filterArr['jobs'] = category;

            // console.log(filterArr);
            sendAjaxRequest('filter', filterArr);
        });

        //Filtering sectors
        $(".filter-sectors li").on("click", function () {
            $(".filter-all li, .filter-sectors li").removeClass("active");
            $(this).addClass("active");

            let category = $(this).data("slug")
            filterArr['sectors'] = category;
            sendAjaxRequest('filter', filterArr);
        });


        // Load more
        $(document).on('click', '.load-more', (e) => {
            sendAjaxRequest('load-more')
        })



        // Ajax request
        function sendAjaxRequest(type, filter) {
            if (xhr) xhr.abort()

            const data = {};

            if (type == 'load-more') {
                data['offset'] = $('[data-total]').children().length;
            }

            if (filter) {
                data["filter"] = filter;
            }

            data["ajax-type"] = type;

            // console.log(data)

            xhr = $.ajax({
                url: ajaxRoute,
                method: 'GET',
                data: data,
                dataType: 'json',
                beforeSend: () => {
                    if (type == 'load-more') {
                        $('.load-more').addClass('loading')
                    } else {
                        container.addClass('loading')
                    }
                },
                complete: () => {
                    if (type == 'load-more') {
                        $('.load-more').removeClass('loading')
                    } else {
                        container.removeClass('loading')
                    }
                },
                success: (response) => {
                    // console.log(response)
                    container.html(response)

                    // if (type == 'load-more') {
                    //     const list = $('[data-total]')
                    //     list.append(response)

                    //     const items = list.children()

                    //     if (items.length >= list.data('total')) {
                    //         $('.load-more').remove()
                    //     }
                    // } else {
                    //     container.html(response)
                    // }
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log(errorThrown);
                }
            })
        }
    }
}
